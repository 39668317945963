<template>
    <el-drawer title="权限筛选" :visible="drawer" :direction="direction" class="drawer-box" :before-close="handleClose">
        <el-form ref="drawerForm" :model="drawerForm">
            <el-form-item prop="p_l" class="drawer-item f-cl">
                <span>产品库</span>
                <el-radio-group v-model="drawerForm.p_l" size="medium">
                    <el-radio-button label="R">{{ type == 'batchSettingPower' ? '仅可查看' : '查看' }}</el-radio-button>
                    <el-radio-button label="W">查看/编辑</el-radio-button>
                    <el-radio-button label="D">不可见</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="c_m" class="drawer-item f-cl">
                <span>客户管理</span>
                <el-radio-group v-model="drawerForm.c_m" size="medium">
                    <el-radio-button label="R">{{ type == 'batchSettingPower' ? '仅可查看' : '查看' }}</el-radio-button>
                    <el-radio-button label="W">查看/编辑</el-radio-button>
                    <el-radio-button label="D">不可见</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="e_i" class="drawer-item f-cl">
                <span>企业信息</span>
                <el-radio-group v-model="drawerForm.e_i" size="medium">
                    <el-radio-button label="R">{{ type == 'batchSettingPower' ? '仅可查看' : '查看' }}</el-radio-button>
                    <el-radio-button label="W">查看/编辑</el-radio-button>
                    <!-- <el-radio-button label="D" v-if="!companyInfoNoAvailable">不可见</el-radio-button> -->
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="o_m" class="drawer-item f-cl">
                <span>订单管理</span>
                <el-radio-group v-model="drawerForm.o_m" size="medium">
                    <el-radio-button label="R">{{ type == 'batchSettingPower' ? '仅可查看' : '查看' }}</el-radio-button>
                    <el-radio-button label="W">查看/编辑</el-radio-button>
                    <el-radio-button label="D">不可见</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="p_m" class="drawer-item f-cl">
                <span>人员管理</span>
                <el-radio-group v-model="drawerForm.p_m" size="medium">
                    <el-radio-button label="R">{{ type == 'batchSettingPower' ? '仅可查看' : '查看' }}</el-radio-button>
                    <el-radio-button label="W">查看/编辑</el-radio-button>
                    <el-radio-button label="D">不可见</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="f_s" class="drawer-item f-cl">
                <span>财务管理</span>
                <el-radio-group v-model="drawerForm.f_s" size="medium">
                    <el-radio-button label="R">{{ type == 'batchSettingPower' ? '仅可查看' : '查看' }}</el-radio-button>
                    <el-radio-button label="W">查看/编辑</el-radio-button>
                    <el-radio-button label="D">不可见</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="i_m" class="drawer-item f-cl">
                <span>入库管理</span>
                <el-radio-group v-model="drawerForm.i_m" size="medium">
                    <el-radio-button label="R">{{ type == 'batchSettingPower' ? '仅可查看' : '查看' }}</el-radio-button>
                    <el-radio-button label="W">查看/编辑</el-radio-button>
                    <el-radio-button label="D">不可见</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="a_m" class="drawer-item f-cl">
                <span>人员权限管理</span>
                <el-radio-group v-model="drawerForm.a_m" size="medium">
                    <el-radio-button label="R">{{ type == 'batchSettingPower' ? '仅可查看' : '查看' }}</el-radio-button>
                    <el-radio-button label="W">查看/编辑</el-radio-button>
                    <el-radio-button label="D">不可见</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item class="drawer-modify" v-if="type == 'batchSettingPower'">
                <el-button type="primary" @click="batchModify">提交修改</el-button>
            </el-form-item>
            <el-form-item class="drawer-btns" v-else>
                <el-button @click="resetForm('drawerForm')">重置</el-button>
                <el-button type="primary" @click="submitForm">提交</el-button>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>

<script>
export default {
    name: '',
    props: {
        drawer: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        // 企业信息是否显示不可见按钮
        companyInfoNoAvailable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            direction: 'rtl',
            p_l: '',
            c_m: '',
            e_i: '',
            o_m: '',
            a_m: '',
            f_s: '',
            i_m: '',
            p_m: '',
            drawerForm: {
                "a_m": "",//人员权限管理
                "c_m": "",//客户管理
                "e_i": "",//企业信息
                "f_s": "",//财务统计
                "i_m": "",//库存管理
                "o_m": "",//订单管理
                "p_c": "",//工作牌
                "p_l": "",//产品库
                "p_m": ""//人员管理
            },
        }
    },
    created() {
        if (this.type == 'batchSettingPower') {
            this.drawerForm = {
                "a_m": "D",//人员权限管理
                "c_m": "D",//客户管理
                "e_i": "R",//企业信息
                "f_s": "D",//财务统计
                "i_m": "D",//库存管理
                "o_m": "D",//订单管理
                "p_c": "",//工作牌
                "p_l": "D",//产品库
                "p_m": "D"//人员管理
            }
        }
    },
    methods: {
        handleClose() {
            this.$emit('closeDrawer', false)
        },
        submitForm() {
            this.$emit('submitForm', this.drawerForm)
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$emit('resetForm', this.drawerForm)
        },
        // 企业用户批量设置权限接口
        batchModify() {
            this.$emit('enterpriseUserRevisePermissionList', this.drawerForm)
        }
    },
}

</script>
<style lang='less' scoped>
.drawer-box {
    .el-radio-group {
        padding-left: 10px
    }

    /deep/ .el-drawer__body {
        padding: 0 20px;
    }

    /deep/ .el-radio-button {
        margin-right: 30px;
    }

    /deep/ .el-radio-button .el-radio-button__inner {
        border: 1px solid #DCDFE6;
        border-radius: 5px;
    }

    /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        box-shadow: 0 0 0 0 #409eff;
    }

    /deep/ .el-radio-group {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .drawer-item {
        margin-bottom: 25px;

        span {
            display: block;
            font-weight: bold;
        }
    }

    .drawer-btns {
        margin-top: 40px;

        .el-button {
            width: 35% !important;
        }

        .el-button+.el-button {
            margin-left: 30px;
        }
    }

    .drawer-modify {
        margin: 40px auto 0;

        .el-button {
            width: 50% !important;
        }

        /deep/ .el-form-item__content {
            text-align: center;
        }
    }
}
</style>
